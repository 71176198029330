













































































































































import api from "@/api/index"; //ABP API接口
import { Vue, Component, Ref } from "vue-property-decorator";
import {
  FundDto,
  FundDtoPagedResultDto,
  MerchantCreateOrUpdateDto,
  MerchantDto,
} from "@/api/appService";
import PagedTableView from "@/components/PagedTableView/index.vue";
import AbSelect from "@/components/AbSelect/index.vue";
import ExportButton from "@/components/ExportButton/index.vue";

@Component({
  name: "MerchantList",
  components: {
    PagedTableView,
    AbSelect,
    ExportButton,
  },
})
export default class MerchantList extends Vue {
  fundList: FundDto[] = [];
  SyncStatusList = [
    {
      value: false,
      label: "未同步",
    },
    {
      value: true,
      label: "已同步",
    },
  ];
  queryForm = {};
  isCreate = true;
  isShow = false;
  form: MerchantCreateOrUpdateDto = {
    id: 0,
    unitName: undefined,
    fundId: undefined,
    unitAddress: "",
    principalName: "",
    principalMobilePhone: "",
    principalEmailAddress: "",
    contactName: "",
    contactMobilePhone: "",
    contactEmailAddress: "",
  };
  btnDisabled = false;

  created() {
    this.fetchFundList();
  }

  async fetchFundList() {
    await api.fund
      .getAll({ maxResultCount: 100000 })
      .then((res: FundDtoPagedResultDto) => {
        this.fundList = res.items ?? [];
      });
  }

  // 获取表数据
  fetchData(params: any) {
    return api.merchant.getAll(params);
  }

  // 新增
  handleCreate() {
    this.isCreate = true;
    this.isShow = true;
    this.btnDisabled = false;
    this.form.id = undefined;
    this.form.fundId = undefined;
    this.form.unitName = undefined;
  }

  // 修改
  handleEdit(id: number) {
    this.isCreate = false;
    this.btnDisabled = false;
    api.merchant.get({ id: id }).then((res: any) => {
      if (res) {
        this.form = { ...res };
      }
    });
    this.isShow = true;
  }

  // 收款账户
  jumpAccount(row: MerchantDto) {
    this.$router.push({
      name: "merchantBankAccount",
      query: { merchantId: row.id!.toString() },
    });
  }

  // 删除
  handleDelete(row: MerchantDto) {
    this.$confirm("你确定删除吗?", "提示").then(async () => {
      await api.merchant.delete({ id: row.id }).then((res: any) => {
        this.$message({
          type: "success",
          message: "删除成功!",
        });
      });
    });
  }

  handleEnable(row: MerchantDto) {
    this.$confirm("你确定启用吗?", "提示").then(async () => {
      await api.merchant
        .enableOrDisable({ body: { id: row.id } })
        .then((res: any) => {
          this.$message({
            type: "success",
            message: "启用成功!",
          });
        });
    });
  }

  handleDisable(row: MerchantDto) {
    this.$confirm("你确定禁用吗?", "提示").then(async () => {
      await api.merchant
        .enableOrDisable({ body: { id: row.id } })
        .then((res: any) => {
          this.$message({
            type: "success",
            message: "禁用成功!",
          });
        });
    });
  }

  handleSync(row: MerchantDto) {
    this.$confirm("你确定同步至用友吗?", "提示").then(async () => {
      await api.merchant.syncSupplierToNc({ id: row.id }).then((res: any) => {
        this.$message({
          type: "success",
          message: "同步成功!",
        });
      });
    });
  }

  async handleSyncBankInfo() {
    await api.bankType.syncBankTypesFromNc().then((res: any) => {
      this.$message({
        type: "success",
        message: "银行类型同步成功!",
      });
    });
    await api.bankName.syncBankNamesFromNc().then((res: any) => {
      this.$message({
        type: "success",
        message: "银行名称同步成功!",
      });
    });
  }

  async save() {
    this.btnDisabled = true;
    (this.$refs.dataForm as any).validate(async (valid: boolean) => {
      if (valid) {
        if (this.form!.id) {
          await api.merchant
            .update({
              body: this.form,
            })
            .then(() => {
              this.$message.success("更新成功");
              this.isShow = false;
            })
            .catch(() => {
              this.btnDisabled = false;
            });
        } else {
          await api.merchant
            .create({
              body: this.form,
            })
            .then(() => {
              this.$message.success("更新成功");
              this.isShow = false;
            })
            .catch(() => {
              this.btnDisabled = false;
            });
        }
      } else {
        this.$message.error("请检查表单！");
        this.btnDisabled = false;
      }
    });
  }

  roleRule = {
    fundId: [
      {
        required: true,
        message: "基金必填",
        trigger: "blur",
      },
    ],
    unitName: [
      {
        required: true,
        message: "收款账户必填",
        trigger: "blur",
      },
      {
        max: 255,
        message: "收款账户最长不可超过255个字符",
        trigger: "blur",
      },
    ],
  };
}
